.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.api-button {
  margin: 10px;
  display: inline-block;
}

.footer {
  padding: 10px;
  min-height: 0px;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  width: 98vw;
  max-width: unset;
}

.no-padding {
  padding: 0px;
}

.jumbo-tall {
  height: 100%;
}


.sidenav {
  width: 10rem; /* Set the width of the sidebar */
  background-color: #ddd; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  color: black;
  display: table;
  height: 100%;

  div {
    padding: 1rem 1rem;
    text-decoration: none;
    font-family: Arial;
  }
}

#app {
  overflow: hidden;
}

/* When you mouse over the navigation links, change their color */
.sidenav div:hover {
  background-color: #0066f9;
  color: white;
}

.selected {
  color: #0066f9;
}

.page-container {
  display: inherit;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.pc-modal {
    padding-top: 1rem;
  }
}

.jumbo-tall {
  width: 100%;
}

.wide-modal {
  max-width: 50%;
  min-width: 300px;
}
.extra-wide-modal {
  max-width: 90%;
  min-width: 300px;
}

.actions-bar {
  display: contents;

  .action-row {
    margin-left: 1rem;
    margin-top: 1rem;
  }
}

/* When you mouse over the navigation links, change their color */
.navbar li:hover {
  background-color: #ddd;
}

.main-page {
  width: 100%;
}

.tableFixHead          { overflow-y: auto; height: 90%; }
.tableFixHead thead th { position: sticky; top: -1px; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px;  }
th     { background:#eee;  }

#profileDropDown {
  min-width: 9.5rem;
  text-align: right;
}

.loading {
  width: 100%;
  margin-left: 50rem;
  margin-top: 10rem;
}